<template>
  <!-- 偏差统计 -->
  <div class="deviation">
    <div class="day-deviation box h25per">
      <div class="common-one-w box day-one">
        <textIcon text="当日偏差"></textIcon>
        <span class="day-date mar-l55" v-if="statistical.list.length">{{ statistical.list[0] }}</span>
        <span class="day-date mar-l28" v-if="statistical.list.length">{{ statistical.list[1] }}</span>
        <span class="day-date mar-l28" v-if="statistical.list.length">{{ statistical.list[2] }}</span>

        <span class="day-date-text mar-l28"> <span class="day-icon-bt">次</span> </span>
      </div>
      <div class="flex1 box">
        <div class="day-two-com day-two-com-w mar-r32">
          <textIcon text="自动化测点"></textIcon>
          <div class="day-two-line">
            <div class="day-two-wrap font20 box mar-t10">
              <div class="day-two-wrap-item box flex1">日偏差</div>
              <div class="flex1 day-two-big-font ci flex1" v-if="statistical.automationData">{{ statistical.automationData.biasCount }}<span class="com-text font16">次</span></div>
            </div>
            <div class="day-two-wrap font16 box mar-t28">
              <div class="day-two-wrap-item box flex1">日偏差测点</div>
              <div class="flex1">偏差占比</div>
            </div>
            <div class="day-two-wrap box mar-t12">
              <div class="day-two-wrap-item flex1 pad-l24" v-if="statistical.automationData">{{ statistical.automationData.biasTarget }}个</div>
              <div class="flex1 pad-l24" v-if="statistical.automationData">{{ statistical.automationData.deviationRate }}</div>
            </div>
          </div>
        </div>

        <div class="day-two-com day-two-com-w mar-r32">
          <textIcon text="人工测点"></textIcon>
          <div class="day-two-line">
            <div class="day-two-wrap font20 box mar-t10">
              <div class="day-two-wrap-item box flex1">日偏差</div>
              <div class="flex1 day-two-big-font ci flex1" v-if="statistical.artificialData">{{ statistical.artificialData.biasCount }}<span class="com-text font16">次</span></div>
            </div>
            <div class="day-two-wrap font16 box mar-t28">
              <div class="day-two-wrap-item box flex1">日偏差测点</div>
              <div class="flex1">偏差占比</div>
            </div>
            <div class="day-two-wrap box mar-t12">
              <div class="day-two-wrap-item flex1 pad-l24" v-if="statistical.artificialData">{{ statistical.artificialData.biasTarget }}个</div>
              <div class="flex1 pad-l24" v-if="statistical.artificialData">{{ statistical.artificialData.deviationRate }}</div>
            </div>
          </div>
        </div>
        <div class="flex1 day-two-com box">
          <div class="flex1 text-cen day-two-img-item box">
            <img src="../../assets/img/deviation/zidonghuacedian-icon@2x.png" />
            <div class="day-two-big-font">{{ statistical.automation }}</div>
            <p class="com-text font16">自动化测点（个）</p>
          </div>
          <div class="flex1 text-cen day-two-img-item box">
            <img src="../../assets/img/deviation/rengongcedian-icon@2x.png" />
            <div class="day-two-big-font">{{ statistical.artificial }}</div>
            <p class="com-text font16">人工测点（个）</p>
          </div>
          <div class="flex1 text-cen day-two-img-item box">
            <img src="../../assets/img/deviation/cedianzongshu-icon@2x.png" />
            <div class="day-two-big-font">{{ statistical.targetCount }}</div>
            <p class="com-text font16">测点总数量（个）</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二行 -->
    <div class="day-deviation box pad-t20 h35per">
      <div class="common-one-w trend-wrapper position-rel" v-if="!deviationTrend">
        <textIcon text="偏差趋势"></textIcon>
        <div class="deviation-trend" id="deviation-trend"></div>
        <div class="charts-name"><img src="@/assets/images/homeicon/circle.png" alt="" /><span class="mar-R20"> 自动化测点</span> <img src="@/assets/images/homeicon/yuan.png" alt="" /><span>人工测点</span></div>
      </div>
      <div class="common-one-w trend-wrapper position-rel no-chart3" v-else>
        <textIcon text="偏差趋势"></textIcon>
        <p>暂无数据</p>
      </div>
      <div class="flex1 box" v-if="!distribution">
        <div class="flex1 mar-r32 mar-l32 position-rel trend-wrapper">
          <textIcon text="偏差率分布"></textIcon>
          <div class="day-two-charts deviation-trend" id="scatter">
            <svg id="svg"></svg>
          </div>
          <div class="charts-name"><img src="@/assets/images/homeicon/circle2.png" alt="" /><span class="mar-R20"> 自动化测点</span> <img src="@/assets/images/homeicon/yuan2.png" alt="" /><span>人工测点</span></div>
        </div>
        <div class="flex1 trend-wrapper">
          <textIcon text="偏差分布"></textIcon>
          <div class="day-two-line deviation-trend" id="distribution"></div>
          <div class="trend-legend">
            <p class="trend-legend-auto">自动化测点</p>
            <p class="trend-legend-person">人工测点</p>
          </div>
        </div>
      </div>

      <div class="flex1 box" v-else>
        <div class="flex1 mar-r32 mar-l32 position-rel trend-wrapper no-chart3">
          <textIcon text="偏差率分布"></textIcon>
          <p>暂无数据</p>
        </div>
        <div class="flex1 trend-wrapper no-chart3">
          <textIcon text="偏差分布"></textIcon>
          <p>暂无数据</p>
        </div>
      </div>
    </div>

    <!-- 第三行 -->

    <div class="day-deviation-three box mar-t20 h40per" v-if="deviationRecordList.length">
      <div class="box day-deviation-three-title">
        <textIcon text="偏差记录"></textIcon>
        <div class="deviation-more pointer" @click="checkMore">更多记录</div>
      </div>
      <div class="flex1 mar-t10 box deviation-char-list">
        <div class="day-charts-list-w flex1 box charts-list-deviation-column" :style="{ marginLeft: index == 0 ? 0 : '33px' }" v-for="(item, index) in deviationRecordList" :key="index">
          <div class="day-charts-item1 flex1" :id="'devia' + index">图表</div>
          <div class="">
            <myList :obj="item"></myList>
          </div>
        </div>
      </div>
    </div>
    <div class="day-deviation-three box mar-t20 h40per no-chart3" v-else>
      <div class="box day-deviation-three-title">
        <textIcon text="偏差记录"></textIcon>
        <!-- <div class="deviation-more pointer" @click="checkMore">更多记录</div> -->
      </div>
      <div class="flex1 mar-t10 box deviation-char-list">
        <p style="margin-top: -42px">
          <img src="../../assets/images/noData.png" style="width: 70px; height: 60px; margin-bottom: 8px" />

          <span>暂无数据</span>
        </p>
      </div>
    </div>

    <devTable :visible="visible" ref="devtable"></devTable>
  </div>
</template>
<script>
import textIcon from "./components/titleIcon.vue";
import myList from "./components/myList";
import * as echarts from "echarts";
import devTable from "@/components/devalition/devTable.vue";
// import dagreD3 from "dagre-d3";
// import * as d3 from "d3";

export default {
  name: "deviation",
  components: { textIcon, myList, devTable },
  data() {
    return {
      visible: false,
      // projectId: 10004,
      projectId: sessionStorage.getItem("projectId"),
      statistical: { list: [] },
      deviationRecordList: [],
      deviationTrend: false,
      distribution: false
    };
  },
  created() {
    this.getStatistical();
    this.getStatisticalTrend();
    this.getScatter();
    this.getStatisticalRecord();
  },
  methods: {
    checkMore() {
      this.visible = true;
      this.$refs["devtable"].getDataList();
    },
    deviationClose() {
      console.log("deviationClose");
      this.visible = false;
    },
    getStatistical() {
      this.$axios.get(`${this.baseURL}bias/statistical/data/${this.projectId}`).then((res) => {
        console.log("测点偏差", res);
        if (res.data.status == "200") {
          this.statistical = res.data.data;
          this.statistical["list"] = [];
          if (res.data.data.biasCount >= 0 && res.data.data.biasCount <= 9) {
            this.statistical["list"].push("0");
            this.statistical["list"].push("0");
            this.statistical["list"].push(res.data.data.biasCount);
          }
          if (res.data.data.biasCount > 9 && res.data.data.biasCount <= 99) {
            let arr = res.data.data.biasCount.toString().split("");
            this.statistical["list"].push("0");
            for (let i = 0; i < arr.length; i++) {
              this.statistical["list"].push(arr[i]);
            }
          }
          if (res.data.data.biasCount > 99 && res.data.data.biasCount <= 999) {
            let arr = res.data.data.biasCount.toString().split("");

            for (let i = 0; i < arr.length; i++) {
              this.statistical["list"].push(arr[i]);
            }
          }
          if (res.data.data.biasCount > 999) {
            this.statistical["list"].push("9");
            this.statistical["list"].push("9");
            this.statistical["list"].push("9");
          }
          if (!res.data.data.biasCount || res.data.data.biasCount < 0) {
            this.statistical["list"].push("0");
            this.statistical["list"].push("0");
            this.statistical["list"].push("0");
          }
        }
      });
    },
    getStatisticalTrend() {
      this.$axios.get(`${this.baseURL}bias/statistical/get/trend/${this.projectId}`).then((res) => {
        console.log("测点偏差趋势", res);
        if (res.data.status == "200") {
          console.log("ssss", this.$chart1, this.$chart3, this.trend);
          let xData = [],
            y1Data = [],
            y2Data = [];
          for (let i = 0; i < res.data.data.length; i++) {
            // xData.push(this.formateDate(res.data.data[i].date));
            xData.push(res.data.data[i].date);
            y1Data.push(res.data.data[i].automation);
            y2Data.push(res.data.data[i].artificial);
          }
          if (!xData.length && !y1Data.length && !y2Data.length) {
            this.deviationTrend = true;
            return;
          }
          document.getElementById("deviation-trend").removeAttribute("_echarts_instance_");

          console.log("xdata", xData, "y1data", y1Data, "y2data", y2Data);
          this.$chart3.trend("deviation-trend", [], xData, y2Data, y1Data);
        }
      });
    },
    formateDate(str) {
      if (!str.length) {
        let d = new Date();
        let s = d.getMonth() + 1 + "月" + d.getDate() + "日";
        return s;
      }
      let n = new Date(str);
      let m = n.getMonth() + 1 + "月" + n.getDate() + "日";
      return m;
    },
    drawSctter(data) {
      class Scatter {
        constructor() {
          this.width = document.getElementById("scatter").offsetWidth;
          this.height = document.getElementById("scatter").offsetHeight;
          this.margin = { top: 10, left: 80, bottom: 40, right: 10 };
          this.innerwidth = this.width - this.margin.left - this.margin.right;
          this.innerHeight = this.height - this.margin.top - this.margin.bottom;

          this.x_key = "x";
          this.y_key = "y";
        }
        init(data) {
          this.data = data;

          const svg = d3.select("#svg");

          svg.attr("xmlns", "http://www.w3.org/2000/svg").attr("xmlns:xlink", "http://www.w3.org/1999/xlink").attr("viewBox", `0 0 ${this.width} ${this.height}`).attr("width", this.width).attr("height", this.height);

          this.svg = svg;

          this.maingroup = svg.append("g").attr("id", "maingroup").attr("transform", `translate(${this.margin.left},${this.margin.top})`);

          this.addAxis();
          this.drawPoint();
          this.drawLegend();
        }
        addAxis() {
          let x_max = d3.max(this.data, (ele) => ele[this.x_key]) + 2;
          let y_max = d3.max(this.data, (ele) => ele[this.y_key]) + 2;

          const xScale = d3
            .scaleLinear()
            .domain([0, 1, x_max])
            .range([0, this.innerwidth / 2, this.innerwidth]);

          const yScale = d3
            .scaleLinear()
            .domain([y_max, y_max / 2, 0])
            .range([0, this.innerHeight / 8, this.innerHeight]);

          this.xScale = xScale;
          this.yScale = yScale;

          //创建坐标轴
          const yAxis = d3.axisLeft(yScale).tickValues([0.3, 1, 5, 10]).tickFormat(d3.format(".1f")).tickSizeOuter(0);

          const xAxis = d3.axisBottom(xScale).tickValues([0.5, 1, 5]).tickFormat(d3.format(".1f")).tickSizeOuter(0);

          const xAxisGroup = this.maingroup.append("g").call(xAxis).attr("id", "xAxis").attr("transform", `translate(0,${this.innerHeight})`);
          const xAxisGroup1 = this.maingroup.append("g").call(xAxis).attr("id", "xAxis").attr("transform", `translate(0,${this.innerHeight})`);
          const xAxisGroup2 = this.maingroup.append("g").call(xAxis).attr("id", "xAxis").attr("transform", `translate(0,${this.innerHeight})`);

          const yAxisGroup = this.maingroup.append("g").call(yAxis).attr("id", "yAxis");

          xAxisGroup.selectAll(".tick line").clone().attr("y2", -10);

          yAxisGroup.selectAll(".tick line").clone().attr("x2", 10);

          yAxisGroup
            .append("text")
            .text("Host preference index")
            .attr("font-size", "2em")
            .attr("fill", "black")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)") //坐标轴也跟着旋转
            .attr("x", -this.innerHeight / 2)
            .attr("y", -40);

          xAxisGroup
            .append("text")
            .text("Invasiveness index")
            .attr("font-size", "2em")
            .attr("fill", "black")
            .attr("text-anchor", "middle")
            // .attr('transform','rotate(-90)')//坐标轴也跟着旋转
            .attr("x", this.innerwidth / 2)
            .attr("y", 40);
        }

        drawPoint() {
          let pointG = this.maingroup
            .append("g")
            .selectAll("point")
            .data(this.data)
            .join("g")
            .attr("transform", (d) => `translate(${this.xScale(d[this.x_key])},${this.yScale(d[this.y_key])})`);

          pointG
            .append("path")
            .attr("d", (d) => {
              return d3
                .symbol()
                .type(d3.symbolCircle)
                .size(d.size * 110)();
            })
            .attr("fill", (d) => {
              return d.type == 1 ? "#637db2" : "#eb978e";
            })
            .attr("stroke", "black")
            .attr("stroke-width", "2")
            .attr("fill-opacity", "0.8");

          pointG
            .append("text")
            .text((d) => d.type)
            .attr("dx", 15)
            .attr("dy", -4);
        }

        drawLegend() {
          let G = this.maingroup.append("g");

          G.append("circle")
            .attr("cx", this.innerwidth - 80)
            .attr("cy", this.innerHeight - 40)
            .attr("r", 6)
            .attr("fill", "#637db2")
            .attr("stroke", "black")
            .attr("stroke-width", "2");

          let G1 = this.maingroup.append("g");

          G1.append("circle")
            .attr("cx", this.innerwidth - 20)
            .attr("cy", this.innerHeight - 10)
            .attr("r", 6)
            .attr("fill", "#637db2")
            .attr("stroke", "black")
            .attr("stroke-width", "2")
            .on("mouseover", function (e) {
              console.log("添加事件", e);
              // document.getElementById("scatter").style.position = "relative";
              let oDiv = document.createElement("div");
              oDiv.style.position = "absolute";
              oDiv.style.left = e.clientX + "px";
              oDiv.style.top = e.clientY + "px";
              oDiv.innerHTML = "张三";
              document.getElementById("scatter").appendChild(oDiv);
            });
          // G.append("text")
          //   .attr("x", this.innerwidth - 70)
          //   .attr("y", this.innerHeight - 36)
          //   .text("ajsfjafhaksfhsa");

          // this.maingroup
          //   .append("line")
          //   .attr("x1", 20)
          //   .attr("y1", this.innerHeight - 20)
          //   .attr("x2", this.innerwidth - 20)
          //   .attr("y2", 20)
          //   .attr("stroke", "black")
          //   .attr("stroke-width", 4);
        }
      }

      const st = new Scatter();
      d3.tsv("/demo/data/scatter_data.tsv", d3.autoType).then(function (data) {
        st.init(data);
      });

      return;

      var dataset = [];
      for (let i = 0; i < 6; i++) {
        let x = Math.random();
        let y = Math.random();
        dataset.push([x, y]);
      }
      let svg = d3.select("#scatter").append("svg").attr("width", "100%").attr("height", "100%");
      let padding = { left: 50, right: 30, top: 20, bottom: 30 };
      var xCale = d3.scaleLinear().domain([0, 1]).range([0, 200]);
      var yCale = d3.scaleLinear().domain([0, 1]).range([0, 200]);
      var height = 200,
        width = 200;
      yCale.range([height, 0]);

      // var xAxis = d3.svg.axis().scale(xCale).orient("bottom").ticks(5);
      // var yAxis = d3.svg.axis().scale(yCale).orient("left").ticks(5);
      const yAxis = d3.axisLeft(yCale).tickValues([0.3, 1, 5, 10]).tickFormat(d3.format(".1f")).tickSizeOuter(0);

      const xAxis = d3.axisBottom(xCale).tickValues([0.5, 1, 5]);

      svg.append("g").attr("class", "axis").attr("transform", `translate(${padding.left},${padding.top})`).call(xAxis);
      svg.append("g").attr("class", "axis").attr("transform", `translate(${padding.left},${padding.top})`).call(yAxis);
      svg
        .selectAll("circle")
        .data(dataset)
        .transition()
        .duration(1000)
        .attr("cx", function (d, i) {
          return padding.left + xCale[d[0]];
        })
        .attr("cy", function (d, i) {
          return height + padding.top - yCale[d[1]];
        })
        .enter()
        .append("circle")
        .attr("cx", padding.left)
        .attr("cy", height + padding.top)
        .attr("r", 5)
        .attr("fill", "white")
        .attr("stroke", "black")
        .transition()

        .duration(1000)
        .attr("cx", function (d, i) {
          return padding.left + xCale[d[0]];
        })
        .attr("cy", function (d, i) {
          return height + padding.top - yCale[d[1]];
        })
        .exit()
        .remove();
    },
    getScatter() {
      this.$axios.get(`${this.baseURL}bias/statistical/get/rate/${this.projectId}`).then((res) => {
        console.log("测点偏差率分布", res);
        if (res.data.status == "200") {
          let arr = res.data.data.vos,
            xData = [],
            y1Data = [],
            y2Data = [];
          // 2022.09.09 liang修改
          arr.forEach((item) => {
            xData.push(new Date(item.date));
            if (item.deviationRate && item.deviationRate.length) {
              item.deviationRate.forEach((eve) => {
                // xData.push(eve.deviationDate);
                if (eve.targetType == 1) {
                  // console.log("eve", eve);
                  y1Data.push([eve.deviationDate, parseFloat(eve.deviationRate)]);
                  // y1Data.push(parseFloat(eve.deviationRate));
                } else {
                  y2Data.push([eve.deviationDate, parseFloat(eve.deviationRate)]);
                  // y2Data.push(parseFloat(eve.deviationRate));
                }
              });
            }
          });

          // this.drawSctter();

          // for (let i = 0; i < arr.length; i++) {
          //   if (arr[i].targetType == 1) {
          //     y1Data.push([arr[i].deviationDate, parseFloat(arr[i].deviationRate)]);
          //   } else {
          //     y2Data.push([arr[i].deviationDate, parseFloat(arr[i].deviationRate)]);
          //   }
          // }
          console.log("y1data", y1Data, "y2data", y2Data, "xdata", xData);
          if (!y1Data.length && !y2Data.length) {
            this.distribution = true;
            return;
          }
          console.log("---------------------", xData, y1Data, y2Data);
          this.$nextTick(() => {
            document.getElementById("deviation-trend").removeAttribute("_echarts_instance_");
            this.$chart3.scatter("scatter", xData, y1Data, y2Data);
            this.$chart3.distribution("distribution", res.data.data.deviationDis);
          });
        }
      });
    },
    getStatisticalRecord() {
      this.$axios.get(`${this.baseURL}bias/statistical/get/record/${this.projectId}`).then((res) => {
        console.log("测点偏差纪律", res);
        if (res.data.status == "200") {
          let recordList = [],
            chartsList = [],
            date = [],
            arr = res.data.data;
          arr.length &&
            arr.forEach((item) => {
              let reg = {
                actualValue: item.actualValue,
                deviationDate: item.deviationDate,
                deviationRate: item.deviationRate,
                targetName: item.targetName
              };
              recordList.push(reg);
              // let reg1 = { time: [], data: [], type: item.targetType, unit: item.unit };
              // item.list &&
              //   item.list.forEach((itm) => {
              //     reg1.time.push(itm.deviationDate);
              //     reg1.data.push(itm.actualValue);
              //   });
              // chartsList.push(JSON.parse(JSON.stringify(reg1)));

              let reg1 = { person: [], auto: [], type: item.targetType, unit: item.unit },
                d = [];
              item.list &&
                item.list.forEach((itm) => {
                  let arr = [this.formateDate(itm.deviationDate), itm.actualValue];
                  if (itm.targetType == 1) {
                    reg1.auto.push(JSON.parse(JSON.stringify(arr)));
                  } else {
                    reg1.person.push(JSON.parse(JSON.stringify(arr)));
                  }
                  d.push(new Date(this.formateDate(itm.deviationDate)).getTime());
                });
              date.push(JSON.parse(JSON.stringify(d)));
              chartsList.push(JSON.parse(JSON.stringify(reg1)));
            });
          this.deviationRecordList = recordList;

          console.log("测点偏差记录", date, chartsList);

          // deviationLine
          this.$nextTick(() => {
            chartsList.forEach((itmm, indx) => {
              document.getElementById("devia" + indx).removeAttribute("_echarts_instance_");
              this.$chart3.deviationLine("devia" + indx, itmm, date[indx]);
            });
          });
        }
      });
    },
    formateDate(str) {
      return str;
      // return new Date().getFullYear() + "-" + str + ":00";
    }
  }
};
</script>
<style lang="scss" scoped>
.circle {
  color: red;
  background-color: red;
}
.deviation {
  width: 100%;
  // min-height: 100%;

  .day-deviation-three {
    flex-direction: column;
    .day-deviation-three-title {
      justify-content: space-between;
      .deviation-more {
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #27a6ff;
        letter-spacing: 0;
      }
    }
  }
  .common-one-w {
    width: 30vw;
  }
  .position-rel {
    position: relative;
  }
  .charts-name {
    position: absolute;
    right: 4%;
    top: 35px;

    display: flex;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    align-items: center;
    img {
      margin-right: 7px;
      width: 10px;
      height: 10px;
    }
  }
  .mar-R20 {
    margin-right: 20px;
  }
  .trend-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    .deviation-trend {
      flex: 1;
    }
    .trend-legend {
      position: absolute;
      right: 0;
      top: 25px;
      .trend-legend-auto {
        &::before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 6px;
          background-color: #f9ff8b;
          position: absolute;
          left: -28px;
          top: 5px;
        }
      }
      .trend-legend-person {
        &::before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 6px;
          background-color: #93fbd2;
          position: absolute;
          left: -28px;
          top: 5px;
        }
      }
      .trend-legend-auto,
      .trend-legend-person {
        margin-bottom: 6px;
        position: relative;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
      }
    }
  }

  .day-deviation {
    .day-one {
      align-items: center;

      .day-date {
        width: 68px;
        height: 88px;
        display: inline-block;
        border: 1px solid #2f7fef;
        background: #081a3c;
        font-family: ZhenyanGB-Regular;
        font-weight: 400;
        font-size: 44px;
        color: #cbe1fc;
        line-height: 88px;
        text-align: center;
      }
      .day-date-text {
        height: 88px;
        // display: table-cell;
        // vertical-align: bottom;
        position: relative;

        .day-icon-bt {
          position: absolute;
          bottom: 0;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 18px;
          color: #cbe1fc;
        }
      }
    }
    .day-two-com-w {
      width: 17vw;
    }
    .day-two-com {
      // height: 230px;
      height: 100%;
      background-image: radial-gradient(circle at 50% 53%, #01042c 0%, #0b2d5a 83%);
      border-radius: 8px;

      box-sizing: border-box;
      padding-top: 1.2%;
      padding-left: 32px;
      .day-two-line {
        padding-left: 12px;
        .day-two-wrap {
          font-family: PingFangSC-Regular;
          font-weight: 400;

          color: #a0b4cb;

          .day-two-wrap-item {
            line-height: 1;
            align-items: center;
          }

          .ci {
            span {
              margin-left: 11px;
            }
          }
        }
      }
    }

    .day-two-img-item {
      flex-direction: column;
      align-items: center;
      img {
        width: 66px;
        height: 66px;
        margin-bottom: 6%;
      }
      p {
        margin-top: 6%;
      }
    }
  }
  .day-two-big-font {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 40px;
    color: #73d0ff;
  }
  .com-text {
    font-family: PingFangSC-Regular;
    font-weight: 400;

    color: #a0b4cb;
  }
  .deviation-char-list {
    justify-content: space-between;
  }
  .charts-list-deviation-column {
    flex-direction: column;
  }
  .h25per {
    height: 25%;
  }
  .h35per {
    height: 35%;
  }
  .h40per {
    height: 40%;
  }

  .w281 {
    width: 281px;
  }
  .h270 {
    height: 270px;
  }
  .h190 {
    height: 190px;
  }
  .h310 {
    height: 310px;
  }
  .mar-l32 {
    margin-left: 32px;
  }
  .mar-r32 {
    margin-right: 32px;
  }
  .pad-l24 {
    padding-left: 24px;
  }
  .text-cen {
    text-align: center;
  }
  .mar-l28 {
    margin-left: 28px;
  }
  .mar-l55 {
    margin-left: 55px;
  }
  .mar-t18 {
    margin-top: 18px;
  }
  .mar-t28 {
    margin-top: 24px;
  }
  .mar-t10 {
    margin-top: 1%;
  }
  .mar-t12 {
    margin-top: 12px;
  }
  .mar-t20 {
    margin-top: 20px;
  }
  .pad-t20 {
    padding-top: 20px;
  }
  .mar-t20 {
    margin-top: 20px;
  }
  .font16 {
    font-size: 16px;
  }
  .font20 {
    font-size: 20px;
  }

  .no-chart3,
  .no-chart6 {
    background: rgba(8, 32, 76, 0.6);
    border-radius: 8px;
    position: relative;
    p {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 50%;
      margin-left: -32px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #8a94a6;
      letter-spacing: 0;
    }
  }
  .no-chart6 {
    background: rgba(8, 32, 76, 0.6);
    border-radius: 8px;
  }
}
</style>
