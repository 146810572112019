<template>
  <div class="day-list-i">
    <div class="day-list-i-title h32">{{ obj.targetName }}</div>
    <div class="day-list-res box h32 border1">
      <span class="flex1">实测值</span>
      <span class="flex1">偏差率</span>
      <span class="flex1">偏差时间</span>
    </div>
    <div class="day-list-res box h32 color">
      <span class="flex1">{{ obj.actualValue }}</span>
      <span class="flex1">{{ obj.deviationRate }}</span>
      <span class="flex1">{{ obj.deviationDate }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.day-list-i {
  border: 1px solid #143b83;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 12px;
  color: #cbe1fc;
  letter-spacing: 0;
  .color {
    color: #cbe1fc;
  }
  .border1 {
    border-top: 2px solid rgba(204, 218, 255, 0.3);
    border-bottom: 1px solid rgba(204, 218, 255, 0.3);
  }
  .day-list-res {
    align-items: center;
    background: #0c265a;
  }
  .day-list-i-title {
    background: #071b4f;
    line-height: 32px;
  }
  .text-center {
    text-align: center;
  }
  .h32 {
    height: 32px;
  }
}
</style>
