<template>
  <el-dialog title="记录" :visible="visible" top="10vh" class="my-deviation" :before-close="handleDialogClose">
    <div class="main">
      <div class="box center mb28" style="justify-content: space-between">
        <div class="box center">
          <div class="search box center">
            <span class="sp">监测类型</span>
            <el-select v-model="targetType" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="search box center">
            <span class="sp" style="text-align: right">偏差率</span>
            <el-select v-model="deviationRate" placeholder="请选择">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="search box center">
            <!-- <span class="sp">设备编号</span> -->
            <el-input v-model="searchTxt" placeholder="请输入测点名称"></el-input>
            <!-- <el-button @click="changeValue" class="mlbtn">查询</el-button> -->
          </div>

          <!-- <div class="search box center">
            <span class="sp">设备编号</span>
            <el-input v-model="searchTxt" placeholder="请输入测点编号"></el-input>
            <el-button @click="changeValue" class="mlbtn">查询</el-button>
          </div> -->
        </div>
        <el-button @click="changeValue" class="mlbtn">查询</el-button>
      </div>

      <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-if="tableData.length" :row-key="getRowKey" ref="multipleTable">
        <el-table-column align="center" prop="targetName" label="测点ID" class="fs14"></el-table-column>
        <el-table-column align="center" prop="targetType" label="监测类型">
          <template slot-scope="scope">
            {{ scope.row.targetType == 1 ? "自动化测点" : "人工测点" }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="actualValue" label="实测值"></el-table-column>
        <el-table-column align="center" prop="theoryValue" label="理论值"></el-table-column>
        <el-table-column align="center" prop="deviationRate" label="偏差率"></el-table-column>
        <el-table-column align="center" prop="deviationDate" label="偏差时间"></el-table-column>
        <!-- <el-table-column align="center" prop="target.staticDynamicSelected" label="数据类型">
          <template slot-scope="scope" v-if="scope.row.target && scope.row.target.staticDynamicSelected">{{ scope.row.target.staticDynamicSelected | staticDynamic }}</template>
          <template slot-scope="scope" v-else>{{ staticDynamic }}</template>
        </el-table-column> -->
        <!-- <el-table-column prop="patchSelected" label="缺失数据补齐">
                 <template slot-scope="scope">{{scope.row.patchSelected | trueFalse}}</template>
      </el-table-column>
      <el-table-column prop="outlierSelected" label="异常数据去除">
               <template slot-scope="scope">{{scope.row.outlierSelected | trueFalse}}</template>
      </el-table-column>
      <el-table-column prop="denoisingSelected" label="消噪处理">
                 <template slot-scope="scope">{{scope.row.denoisingSelected | trueFalse}}</template>
      </el-table-column> -->
        <!-- <el-table-column align="center" prop="baselineSelected" label="基线校正">
          <template slot-scope="scope">{{ scope.row.baselineSelected | trueFalse }}</template>
        </el-table-column> -->

        <template slot="empty" class="imgBox">
          <div>
            <img src="../../assets/images/noData.png" alt="" class="imgver" />
            <span>暂无数据</span>
          </div>
        </template>
      </el-table>
      <common-pagination v-show="tableData.length > 0" :pageSize="pageSize" class="pageMain" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
  </el-dialog>
</template>

<script>
import CommonPagination from "../Pagination";
import upload from "@/assets/js/upload.js";
export default {
  name: "deviationList",
  components: {
    CommonPagination
  },
  data() {
    return {
      // projectId: 10004,
      projectId: sessionStorage.getItem("projectId"),

      searchTxt: "", //测点编号
      options: [
        { label: "全部", value: "" },
        { label: "自动化测点", value: 1 },
        { label: "人工测点", value: 2 }
      ],
      typeOptions: [
        { label: "全部", value: "" },
        { label: "100%", value: "100%" },
        { label: "150%", value: "150%" },
        { label: "200%", value: "200%" },
        { label: "300%", value: "300%" }
      ],
      typeValue: "",
      tableData: [],
      pageSize: 10,
      targetType: "",
      currentPage: 1,
      total: 1,
      deviationRate: ""
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // this.$router.push({ path: "/settings", query: { currentPage: this.currentPage, status: this.status, searchTxt: this.searchTxt, typeValue: this.typeValue, t: Date.now() } });
  },

  mounted() {
    this.getDataList();
    // this.operate();
  },
  methods: {
    handleDialogClose() {
      console.log("close", this.$parent);
      this.$parent.deviationClose();
    },
    getRowKey(row) {
      return row.id;
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    getDataList() {
      let data = {
        deviationRate: this.deviationRate,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        projectId: this.projectId,
        targetName: this.searchTxt,
        targetType: this.targetType
      };
      this.$axios.post(`${this.baseURL}bias/statistical/page/record`, data).then((res) => {
        console.log("数据处理列表qqqqqqqqq", res);
        this.tableData = JSON.parse(JSON.stringify(res.data.data.datas));
        this.total = res.data.data.total;
      });
    },
    changeValue() {
      this.currentPage = 1;
      this.getDataList();
    },

    handleCurrentChange(val) {
      console.log("fenye", val);
      this.currentPage = val;
      this.getDataList();
    }
  }
};
</script>

<style scoped lang="scss">
.my-deviation ::v-deep div.el-dialog {
  width: 960px !important;
  background-image: linear-gradient(0deg, #0a173d 0%, #124286 100%);
  border-image: none;
  border: none;
  border-radius: 4px;
}

.imgver {
  width: 60px;
  height: 55px;
  margin: 0 auto;
}

::v-deep .el-table__empty-text {
  margin-top: 10%;
}

.main {
  height: 100%;

  .pl24 {
    padding-left: 24px;
  }

  .mb48 {
    margin-bottom: 38px;
  }
  .mb28 {
    margin-bottom: 28px;
  }
  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 35px;
  }

  .search {
    ::v-deep .el-input {
      width: 180px;
      height: 32px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667db;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

.sp {
  width: 51px !important;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.7px;
  margin-right: 20px;
}

.mlbtn {
  margin-left: 20px;
}

::v-deep button.el-button.is-disabled,
::v-deep button.el-button.is-disabled:focus,
::v-deep button.el-button.is-disabled:hover {
  background-color: #434f6a !important;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
